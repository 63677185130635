<template>
  <div>
    <div v-if="!userloading && user.login" class="pa-2 basic">
      <v-row dense align="center" justify="center">

        <v-col cols="12" class="limited">

          <v-row dense>
            <v-col cols="3">
              카테고리 관리<span class="caption">(드래그&드롭으로 순서 변경)</span>
              <v-row dense>
                <v-col cols="8">
                  <v-text-field
                    outlined
                    dense
                    v-model="new_category"
                    placeholder="새 카테고리 입력"
                    single-line
                    hide-details />
                </v-col>
                <v-col cols="4">
                  <v-btn block color="primary" @click="category_add">
                    추가
                  </v-btn>
                </v-col>
              </v-row>

              <draggable
                :list="categories"
                class="list-group"
                ghost-class="ghost"
                @start="dragging = true"
                @end="category_move"
              >
                <v-card
                  outlined
                  class="list-group-item mb-1 elevation-0"
                  v-for="each in categories"
                  :key="each._id"
                >
                  <v-row dense>
                    <v-col cols="8" class="pt-2 pl-5">
                      <v-text-field
                        v-model="each.name"
                        single-line
                        class="ma-0 pa-0"
                        @change="category_change_name(each._id, each.name)"
                        />
                    </v-col>
                    <v-col cols="4" align=right>
                      <v-btn
                        text
                        color="red"
                        @click="category_del(each.id, each._id)"
                        :disabled="!!each.children">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </draggable>

            </v-col>

            <v-col cols="9">
              영상 목록 <span class="caption">(드래그&드롭으로 순서 변경)</span>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="new_link"
                    placeholder="새 링크 입력"
                    single-line
                    hide-details />
                </v-col>
                <v-col cols="3">
                  <v-select
                    outlined
                    dense
                    :items="categories"
                    item-text="name"
                    item-value="_id"
                    v-model="selected_category"
                    single-line
                    hide-details />
                </v-col>
                <v-col cols="3">
                  <v-btn color="primary" @click="add">
                    추가
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" v-for="each_category in links" :key="each_category.category">
                  <v-row dense>
                    <v-col cols="12" class="title">
                      {{ each_category.category }}
                    </v-col>
                  </v-row>
                  <v-row dense>


                    <draggable
                      :list="each_category.link"
                      class="col-12"
                      ghost-class="ghost"
                      @start="link_dragging = true"
                      @end="(e) => { move(each_category.category_id, e, each_category.link); }"
                      style="display:inherit;"
                    >
                      <v-col
                        cols="3"
                        v-for="(each_link, idx) in each_category.link"
                        :key="each_link._id"
                        class="caption">

                        <v-card flat :href="'http://youtu.be/'+each_link.link" target="_blank">
                          <v-img :src="'http://img.youtube.com/vi/'+each_link.link+'/hqdefault.jpg'" />
                          {{ each_link.title }}
                        </v-card>
                        <v-btn xSmall text @click="del(each_link._id, each_category.category_id, idx)">
                          <v-icon color="red">delete</v-icon>
                        </v-btn>

                      </v-col>

                    </draggable>

                  </v-row>
                </v-col>
              </v-row>

            </v-col>
          </v-row>

        </v-col>
      </v-row>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex';

import draggable from 'vuedraggable';


import UserLoading from '../User/UserLoading';

let id = 0;
let order = {};

export default {
  components: {
    draggable,
    UserLoading,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      data: "data",
      userloading: "userloading",

    }),
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  data: () => {
    return {
      new_link: "",
      selected_category: "",
      new_category: "",
      categories: [],
      links: [],
      dragging: false,
      link_dragging: false,
    }
  },
  mounted: async function() {
    this.$nextTick(async function() {
      await this.reload_category();
    });
  },
  methods: {
    reload_category: async function() {

      this.categories=[];

      if ( firebase.auth().currentUser ) {
        id = 0;
        const id_token = await firebase.auth().currentUser.getIdToken();
        const categories_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/category', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        for ( const each of categories_.data ) {
          this.categories.push({
            name: each.name,
            id: each.id,
            _id: each._id,
            children: 0,
          });

          order[each._id] = 0;

          id++;
        }

        await this.reload_link();

      } else {
        setTimeout(() => {
          this.reload_category();
        }, 500);
      }
    },

    reload_link: async function() {

      this.links = [];

      if ( firebase.auth().currentUser ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const links_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        for ( const each_category of this.categories ) {

          let t = {
            category: each_category.name,
            category_id: each_category._id,
            link: [],
          };

          for ( const each_link of links_.data ) {

            if ( each_link.category == each_category._id ) {
              t.link.push(each_link);

              each_category.children++;
            }
          }

          order[each_category._id] = each_category.children;

          this.links.push(t);
        }

      } else {
        setTimeout(() => {
          this.reload_link();
        }, 500);
      }

    },

    category_change_name: async function(_id, name) {
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/category/name', {
        email: this.user.data.email,
        _id: _id,
        name: name,
      }, {
        headers: {'id_token': id_token},
      });

      await this.reload_link();
    },

    category_move: async function(e) {
      const from_ = this.categories[e.oldIndex];
      const to_ = this.categories[e.newIndex];
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/category', {
        email: this.user.data.email,
        _id: from_._id,
        id: to_.id,
      }, {
        headers: {'id_token': id_token},
      });
      await axios.put(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/category', {
        email: this.user.data.email,
        _id: to_._id,
        id: from_.id,
      }, {
        headers: {'id_token': id_token},
      });

      let t_id = from_.id;
      this.categories[e.oldIndex].id = this.categories[e.newIndex].id;
      this.categories[e.newIndex].id = t_id;

      await this.reload_link();

    },

    category_add: async function() {
      if ( this.new_category == "") {
        alert("카테고리 명을 입력하세요.");
        return;
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      const r = await axios.post(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/category', {
        email: this.user.data.email,
        name: this.new_category,
        id: id,
      }, {
        headers: {'id_token': id_token},
      });

      this.categories.push({ name: this.new_category, id: id, _id: r.data._id });

      id++;

      order[r.data._id] = 0;

      this.new_category = "";
    },

    category_del: async function(id, _id) {
      if ( !confirm('삭제하시겠습니까?') ) return;

      this.categories.splice(id, 1);

      for ( let each of this.categories ) {
        if ( each.id > id ) {
          each.id = --each.id;
        }
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/category', {
          data: {
            email: this.user.data.email,
            id: id,
            category_id: _id,
          },
          headers: {'id_token': id_token},
      });

      id--;

      await this.reload_link();
    },

    add: async function() {
      if ( this.new_link == "") {
        alert("링크를 입력하세요.");
        return;
      }

      if ( this.selected_category == "") {
        alert("카테고리를 선택하세요.");
        return;
      }

      const selected_category_ = this.selected_category;

      // https://youtu.be/mVbVGdvefkg
      const reg = /(.+?)(\/)(watch\x3Fv=)?(embed\/watch\x3Ffeature=player_embedded\x26v=)?([a-zA-Z0-9_-]{11})+/gm;

      let m;
      if ( (m = reg.exec(this.new_link) ) ) {
        this.new_link = m[5];
      }

      const r2 = await axios.get( "https://www.googleapis.com/youtube/v3/videos", {
        params: {
          id: this.new_link,
          key: api_conf.youtubeapi,
          fields: "items(id,snippet(channelId,title,categoryId),statistics)",
          part: "snippet,statistics"
        }
      });
      if ( r2.status == 200 ) {
        const id_token = await firebase.auth().currentUser.getIdToken();
        const r = await axios.post(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/', {
          email: this.user.data.email,
          link: this.new_link,
          category: this.selected_category,
          title: r2.data.items[0].snippet.title,
          order: order[selected_category_],
        }, {
          headers: {'id_token': id_token},
        });

        for ( const each_link of this.links ) {
          if ( each_link.category_id == selected_category_ ) {
            each_link.link.push({
              link: this.new_link,
              category: this.selected_category,
              title: r2.data.items[0].snippet.title,
              _id: r.data._id,
              order: order[selected_category_] });
          }
        }
        order[selected_category_]++;
      } else {
        alert('YOUTUBE API에 오류가 발생했습니다.')
      }

      this.new_link = "";
      this.selected_category = "";
    },

    del: async function(_id, category_id, idx) {
      if ( !confirm("삭제하시겠습니까?") ) return;

      for ( const each_link of this.links ) {
        if ( each_link.category_id == category_id ) {
          for ( const each of each_link.link ) {
            if ( each.order > idx ) {
              each.order--;
            }
          }

          each_link.link.splice(idx, 1);
        }
      }
      const id_token = await firebase.auth().currentUser.getIdToken();
      await axios.delete(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/', {
          data: {
            email: this.user.data.email,
            id: _id,
            order: idx,
          },
          headers: {'id_token': id_token},
      });

      order[category_id]--;

      //await this.reload_link();
    },

    move: async function(category_id, e, links) {

      const id_token = await firebase.auth().currentUser.getIdToken();

      for ( const [idx, each] of links.entries() ) {
        links[idx].order = idx;
        await axios.put(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/youtube/', {
          email: this.user.data.email,
          _id: each._id,
          order: idx,
        }, {
          headers: {'id_token': id_token},
        });

      }
      //await this.reload_link();

    },

  }

}
</script>

<style scoped>

.theme--dark.v-divider {
    border-color: #FBDE44FF !important;
}

.op_btn {
  position:absolute;
  right:0;
  top:0;
}

.op_btn::before {
  color: transparent;
}

.op_btn::hover {
  color: transparent;
}

</style>
